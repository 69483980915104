import { useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import axios from 'axios';

import { FontAwesomeIcon as FAI } from '@fortawesome/react-fontawesome';
import { faCartShopping, faMoneyCheck, faBuildingColumns, faTags } from '@fortawesome/free-solid-svg-icons';
import { SpinnerDiamond } from 'spinners-react';

import { offers } from './config.js';

import '../../styles/shop/item.css';

function ShopItem() {

    const { id } = useParams();

    const [nick, setNick] = useState("");
    const [email, setEmail] = useState("");
    const [payType, setPayType] = useState();
    const [rulesAccepted, setRulesAccepted] = useState(false);
    const [error, setError] = useState();
    const [redirecting, setRedirecting] = useState(false);

    const handleChangeName = (e) =>  {
        setNick(e.target.value);
        setError();
    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
        setError();
    }

    const handleChangePayType = (value) => {
        setPayType(value);
        setError();
    }

    const handleRulesChange = (e) => {
        setRulesAccepted(e.target.checked);
        setError();
    }

    const handleBuy = () => {
        if (redirecting)
            return;

        if (!payType) {
            setError("Musisz wybrać sposób płatności!")
            return;
        }

        if (!rulesAccepted) {
            setError("Musisz zaakceptować regulamin!")
            return;
        }

        if (!nick || (nick && nick.length > 20)) {
            setError("Musisz podać nick z serwera!")
            return;
        }

        if (!email || !email.includes("@") || !email.includes(".")) {
            setError("Musisz podać swój email!")
            return;
        }

        // eslint-disable-next-line no-useless-escape
        const validEmail = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$');
        const validNick = new RegExp('^[a-zA-Z0-9_]+$');
    
        if (!validEmail.test(email) || !validNick.test(nick)) {
            setError("Twój e-mail lub nick jest niepoprawny!");
            return;
        }

        setRedirecting(true);

        if (payType === "PAYSAFECARD") {
            axios({
                method: "post",
                url: `https://api.e-craft.pl/shop/buy/psc`,
                data: {
                    email: email,
                    player: nick,
                    offerId: id
                }
            }).then((result) => {
                window.location.href = result.data.url;
            }).catch((error) => {
                if (error.response && error.response.status === 429) {
                    setError("[Blad Serwera] Generujesz zbyt wiele transakcji w krotkim czasie!");
                } else {
                    setError("[Blad Serwera] Nie mozna wygenerowac transakcji!");
                }
                setRedirecting(false);
            });
        } else if (payType === "TRANSFER") {
            axios({
                method: "post",
                url: `https://api.e-craft.pl/shop/buy/pbl`,
                data: {
                    email: email,
                    player: nick,
                    offerId: id
                }
            }).then((result) => {
                window.location.href = result.data.URL;
            }).catch((error) => {
                if (error.response && error.response.status === 429) {
                    setError("[Blad Serwera] Generujesz zbyt wiele transakcji w krotkim czasie!");
                } else {
                    setError("[Blad Serwera] Nie mozna wygenerowac transakcji!");
                }
                setRedirecting(false);
            });
        }

    }

    const item = offers[id];

    return (
        <div className="shop">
            { item ?
            <>
                <div className="shop-item">

                    {redirecting ?
                    <div className="shop-redirect">
                        <p>
                            <SpinnerDiamond size="5em" color="#d2003b" speed="100" />
                        </p>
                        Za chwilę zostaniesz przeniesiony...
                    </div> : null }

                    <p className="shop-browser-header"> Płatność </p>
                    
                    <div className="shop-browser-item" key={id}>
                        <div className="shop-browser-item-left-side">
                            {item.image}
                        </div>

                        <div className="shop-browser-item-right-side">
                            <p className="shop-browser-item-header"> {item.title} </p>
                            <p className="shop-browser-item-description"> {item.description} </p>
                            <div className="shop-browser-item-right-side-buttons">
                                <button className="shop-browser-item-price-button"> <FAI icon={faTags} /> {item.cost} </button>
                            </div>
                        </div>
                    </div>

                    {/* <p className="shop-selected-item">
                        {item.image}
                        {item.title}
                        <button className="shop-browser-item-price-button"> <FAI icon={faTags} /> {item.cost} </button>
                    </p> */}
                    <br />

                    <div className="shop-item-container">

                        <p className="shop-item-header"> UZUPEŁNIJ DANE </p>

                        <div className="shop-item-data-container">
                            <div className="shop-item-input-container">
                                <p className="shop-item-input-header">TWÓJ NICK NA SERWERZE</p>
                                <input type="text" placeholder="wpisz nick" onChange={handleChangeName} value={nick} />
                            </div>
                            <div className="shop-item-input-container">
                                <p className="shop-item-input-header">TWÓJ EMAIL</p>
                                <input type="email" placeholder="wpisz email" onChange={handleChangeEmail} value={email} />
                            </div>
                        </div>

                        <p className="shop-item-header"> WYBIERZ METODE PŁATNOŚCI </p>

                        <div className="shop-item-payment-container">
                            <button onClick={() => handleChangePayType("TRANSFER")} className={payType === "TRANSFER" ? "selected-payment" : ""}> <FAI icon={faBuildingColumns} /> PRZELEW / BLIK / PAYPAL </button>
                            <button onClick={() => handleChangePayType("PAYSAFECARD")} className={payType === "PAYSAFECARD" ? "selected-payment" : ""}> <FAI icon={faMoneyCheck} /> PAYSAFECARD </button>
                        </div>

                        <div className="shop-item-summary">
                            <div className="shop-rules-container">
                                <input onChange={handleRulesChange} checked={rulesAccepted} type="checkbox" id="rules" name="rules" />
                                <label htmlFor="rules"> Akceptuje <Link target="_blank" rel="noreferrer" to="/sklep/regulamin"> regulamin </Link> serwisu. </label>
                            </div>
                            {redirecting ? null : <button onClick={handleBuy} className="shop-browser-item-buy-button"> <FAI icon={faCartShopping} /> ZAKUP </button> }
                        </div>

                        <p className="shop-summary-error">
                            {error ? error : '\u00a0' }
                        </p>

                    </div>
                </div>
            </>
            : 
            <p className="shop-browser-header"> Nie odnaleziono oferty! </p>
            }
        </div>
    )
}

export default ShopItem;