import wpln5 from '../../assets/images/wpln5_320.png';
import wpln10 from '../../assets/images/wpln10_320.png';
import wpln20 from '../../assets/images/wpln20_320.png';
import wpln30 from '../../assets/images/wpln30_320.png';
import wpln50 from '../../assets/images/wpln50_320.png';
import wpln100 from '../../assets/images/wpln100_320.png';
import wpln200 from '../../assets/images/wpln200_320.png';

export const offers = {
    "w1": {
        title: "5 WPLN",
        description: "Otrzymasz na serwerze walute, którą będziesz mógł wydać w ItemShopie.",
        cost: "5 ZŁ",
        image: <img className="coin-shadow" src={wpln5} width="64px" alt="Obrazek" />
    },
    "w2": {
        title: "10 WPLN",
        description: "Otrzymasz na serwerze walute, którą będziesz mógł wydać w ItemShopie.",
        cost: "10 ZŁ",
        image: <img className="coin-shadow" src={wpln10} width="64px" alt="Obrazek" />
    },
    "w3": {
        title: "20 WPLN",
        description: "Otrzymasz na serwerze walute, którą będziesz mógł wydać w ItemShopie.",
        cost: "20 ZŁ",
        image: <img className="coin-shadow" src={wpln20} width="64px" alt="Obrazek" />
    },
    "w4": {
        title: "30 WPLN",
        description: "Otrzymasz na serwerze walute, którą będziesz mógł wydać w ItemShopie.",
        cost: "30 ZŁ",
        image: <img className="coin-shadow" src={wpln30} width="64px" alt="Obrazek" />
    },
    "w5": {
        title: "50 WPLN",
        description: "Otrzymasz na serwerze walute, którą będziesz mógł wydać w ItemShopie.",
        cost: "50 ZŁ",
        image: <img className="coin-shadow" src={wpln50} width="64px" alt="Obrazek" />
    },
    "w6": {
        title: "100 WPLN",
        description: "Otrzymasz na serwerze walute, którą będziesz mógł wydać w ItemShopie.",
        cost: "100 ZŁ",
        image: <img className="coin-shadow" src={wpln100} width="64px" alt="Obrazek" />
    },
    "w7": {
        title: "200 WPLN",
        description: "Otrzymasz na serwerze walute, którą będziesz mógł wydać w ItemShopie.",
        cost: "200 ZŁ",
        image: <img className="coin-shadow" src={wpln200} width="64px" alt="Obrazek" />
    },
    "w8": {
        title: "300 WPLN",
        description: "Otrzymasz na serwerze walute, którą będziesz mógł wydać w ItemShopie.",
        cost: "300 ZŁ",
        image: <img className="coin-shadow" src={wpln200} width="64px" alt="Obrazek" />
    },
}