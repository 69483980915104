import { useState, useEffect } from 'react';
import axios from 'axios';
import ReactDOMServer from 'react-dom/server';

import { SpinnerCircular } from 'spinners-react';

import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

import '../../styles/shop/browser.css';

import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon as FAI } from '@fortawesome/react-fontawesome';
import { faCartShopping, faTags } from '@fortawesome/free-solid-svg-icons';

import { offers } from './config.js';

function ShopBrowser() {

	const [recentPurchases, setRecentPurchases] = useState();

    useEffect(() => {

		axios({
			method: "get",
			url: `https://api.e-craft.pl/shop/recent_purchases`
		}).then((result) => {
			setRecentPurchases(result.data);
		})

    }, [])

	return (
		<div className="shop">
			<div className="shop-browser">
				<p className="shop-browser-header"> Lista Usług </p>
				<div className="shop-browser-container">

					{ Object.keys(offers).map(id => {

						const offer = offers[id];

						return (
							<div className="shop-browser-item" key={id}>
								<div className="shop-browser-item-left-side">
									{offer.image}
								</div>
		
								<div className="shop-browser-item-right-side">
									<p className="shop-browser-item-header"> {offer.title} </p>
									<p className="shop-browser-item-description"> {offer.description} </p>
									<div className="shop-browser-item-right-side-buttons">
										<button className="shop-browser-item-price-button"> <FAI icon={faTags} /> {offer.cost} </button>
										<NavLink to={`/sklep/oferta/${id}`}> <button className="shop-browser-item-buy-button"> <FAI icon={faCartShopping} /> ZAKUP </button> </NavLink>
									</div>
								</div>
							</div>
						)
					}) }

				</div>

				<p className="shop-browser-purchases-header"> Ostatnie Zakupy </p>
				<div className="shop-browser-recent-purchases">
					{recentPurchases ?
						<>
						{recentPurchases.map((purchase, index) => {
							const avatar = `https://minotar.net/avatar/${purchase.nick}/48`;

							return (
								<div className="shop-browser-purchase" key={index} 
									data-tooltip-id={`tooltip-${index}`}
									data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
									<div>
										<span><b>{offers[purchase.usluga].title} - {purchase.nick}</b></span>
										<br />
										<span>{new Date(purchase.data).toLocaleString('pl-PL')}</span>
									</div>
										)}
								>
									<img src={avatar} alt="Avatar" />
									<Tooltip id={`tooltip-${index}`} />
								</div>
							)
						})}
						</>
					:  
						<p> <SpinnerCircular size="1em" color="#d2003b" speed="150" /> Pobieranie ostatnich zakupow... </p>
					}
				</div>
			</div>
		</div>
	);
}

export default ShopBrowser;
