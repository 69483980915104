import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Ranking from './pages/Ranking';
import ServerRules from './pages/rules/ServerRules';
import ShopRules from './pages/rules/ShopRules';
import ShopBrowser from './pages/shop/ShopBrowser';
import ShopItem from './pages/shop/ShopItem';
import PaymentStatus from './pages/shop/PaymentStatus';
import Main from './pages/Main';
import Admin from './pages/Admin';
import Credits from './pages/Credits';

import RedirectPage from './pages/RedirectPage';

import Navbar from './layouts/Navbar';
import Footer from './layouts/Footer';

function App() {
	return (
		<Router>
			<div className="app">
				<Navbar />
				<Routes>
					<Route path="/ranking" element={ <Ranking /> }/>
					<Route path="/regulamin" element={ <ServerRules /> }/>
					<Route path="/sklep" element={ <ShopBrowser /> }/>
					<Route path="/sklep/oferta/:id" element={ <ShopItem /> }/>
					<Route path="/sklep/status_platnosci/:id" element={ <PaymentStatus /> }/>
					<Route path="/sklep/regulamin" element={ <ShopRules /> }/>
					<Route path="/autorzy" element={ <Credits /> }/>
					<Route path="/admin/:password?" element={ <Admin /> }/>

					<Route path="/discord" element={ <RedirectPage link="https://discord.com/invite/753jtHG" /> }/>

					<Route path="*" element={ <Main /> }/>
				</Routes>
				<Footer />
			</div>
		</Router>
	);
}

export default App;
