import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';

import '../../styles/shop/payment_status.css';

import { SpinnerCircular } from 'spinners-react';

import SuccessStatus from './statuses/SuccessStatus';
import FailureStatus from './statuses/FailureStatus';
import UnknownStatus from './statuses/UnknownStatus';

function PaymentStatus() {

    const { id } = useParams();

    const [status, setStatus] = useState();

    useEffect(() => {

        if (id === "id" || id === "fail")
            return;

		axios({
			method: "get",
			url: `https://api.e-craft.pl/shop/status/${id}`
		}).then((result) => {
			setStatus(result.data[0].status);
		}).catch(() => {
            setStatus("ERROR");
        })

    }, [id])

    return (
        <div className="shop">
            <div className="payment-status-container">
                <p className="payment-status-header">Status Płatności</p>
                <div className="payment-status-container-card">

                    {id === 'ok' ?
                        <SuccessStatus />
                    : id === 'fail' ? 
                        <FailureStatus />
                    : status ?
                        status === 'SUCCESS' ?
                            <SuccessStatus />
                        : status === 'FAILURE' ?
                            <FailureStatus />
                        : 
                            <UnknownStatus />
                    : 
                        <p className="status-loader">
                            <SpinnerCircular size="5em" color="#d2003b" speed="100" />
                            <br />
                            Pobieranie statusu...
                        </p>
                    }

                </div>
                    
            </div>
        </div>
    )

}

export default PaymentStatus;