import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon as FAI } from '@fortawesome/react-fontawesome';
import { faBars, faCartPlus, faHome, faRankingStar, faScroll } from '@fortawesome/free-solid-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

import '../styles/navbar.css';

import Logo from '../assets/images/logo400.png';

const Navbar = () => {

	const discord = 'https://discord.com/invite/753jtHG';

	const [toggle, setToggle] = useState(false);

	useEffect(() => {
        setToggle(window.innerWidth >= 1150 ? true : false);
	}, []);

	const handleResize = (e) => {
        if (window.innerWidth >= 1150) {
            if (!toggle) {
                setToggle(true);
            }
        } else {
            if (toggle) {
                setToggle(false);
            }
        }
    }

	const toggleNavbar = () => {
        if (window.innerWidth < 1150)
            setToggle(!toggle);
    }

	const hideNavbar = () => {
		if (window.innerWidth < 1150)
			setToggle(false);
	}

	window.addEventListener('resize', handleResize);

	return (
		<div className="navbar">
			<NavLink to="/" draggable="false"> <img src={Logo} alt="Logo" draggable="false" /> </NavLink>
			<div className="navbar-button" onClick={toggleNavbar}>
				<FAI icon={faBars} />
			</div>
			<ul className="navbar-list" style={ toggle ? {display: 'inherit'} : {display: 'none'} }>
                <NavLink onClick={hideNavbar} id="navbar-link" to="/"> <li> <FAI icon={faHome} /> STRONA GŁÓWNA </li> </NavLink>
				<NavLink onClick={hideNavbar} id="navbar-link" to="/sklep"> <li> <FAI icon={faCartPlus} /> SKLEP </li> </NavLink>
                <NavLink onClick={hideNavbar} id="navbar-link" to="/ranking"> <li> <FAI icon={faRankingStar} /> RANKING </li> </NavLink>
                <NavLink onClick={hideNavbar} id="navbar-link" to="/regulamin"> <li> <FAI icon={faScroll} /> REGULAMIN </li> </NavLink>
				<a target="_blank" rel="noreferrer" onClick={hideNavbar} id="navbar-link" href={discord}> <li> <FAI icon={faDiscord} /> DISCORD </li> </a>
            </ul>
		</div>
	);
}

export default Navbar;
