import { useState, useEffect } from 'react';
import axios from 'axios';

import { SpinnerCircular } from 'spinners-react';

import '../styles/components/administration.css';

function Administration() {

    const [persons, setPersons] = useState();

    useEffect(() => {

		axios({
			method: "get",
			url: `https://api.e-craft.pl/administration`
		}).then((result) => {
            setPersons(result.data);
		})

    }, [])

	return (
        <div className="administration">
            <p className="administration-header"> 
                Administracja
            </p>
            {persons ?
                <div className="administration-container">
                    {persons.map((person, index) => {

                        const avatar = `https://minotar.net/avatar/${person.name}/36`;
                        const rankId = person.rank.includes("+") ? person.rank.replace("+", "2") : person.rank;

                        return (
                            <div className="administration-person" key={index}>
                                <div className="administration-person-leftbar">
                                    <img className="administration-person-avatar" src={avatar} alt="Avatar" />
                                </div>
                                <div className="administration-person-rightbar">
                                    <p className="administration-person-name"> {person.name} </p>
                                    <p className="administration-person-rank" id={rankId}> {person.rank} </p>
                                </div>
                            </div>
                        )
                    })}
                </div>
                :
                <p> <SpinnerCircular size="1em" color="#d2003b" speed="150" /> Pobieranie administracji... </p>
            }
        </div>
    )
}

export default Administration;
