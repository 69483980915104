import '../styles/components/panes.css';
import { FontAwesomeIcon as FAI } from '@fortawesome/react-fontawesome';
import { faUsers, faUserPen, faShieldHalved } from '@fortawesome/free-solid-svg-icons';

function PanesContainer(props) {

	return (
        <div className="panes-container">
            <div className="pane">
                <div className="pane-leftside">
                    <div className="pane-left-top"> {props.usersAmount} </div>
                    <div className="pane-left-bottom"> Zarejestrowanych Graczy </div>
                </div>
                <div className="pane-rightside">
                    <FAI icon={faUserPen} />
                </div>
            </div>

            <div className="pane">
                <div className="pane-leftside">
                    <div className="pane-left-top"> {props.guildsAmount} </div>
                    <div className="pane-left-bottom"> Założonych Gildii </div>
                </div>
                <div className="pane-rightside">
                    <FAI icon={faShieldHalved} />
                </div>
            </div>

            <div className="pane">
                <div className="pane-leftside">
                    <div className="pane-left-top"> {props.playerCount} </div>
                    <div className="pane-left-bottom"> Osób na Serwerze </div>
                </div>
                <div className="pane-rightside">
                    <FAI icon={faUsers} />
                </div>
            </div>
        </div>
    )
}

export default PanesContainer;
