import React from 'react'

import '../styles/footer.css';

import { FontAwesomeIcon as FAI } from '@fortawesome/react-fontawesome';
import { faDiscord, faYoutube, faTeamspeak } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {

    const year = new Date().getFullYear();

    const discord = 'https://discord.com/invite/753jtHG';
    const youtube = 'https://www.youtube.com/c/xN0MANDx/';
    const teamspeak = 'ts3server://ts.e-craft.pl?port=9987';


	return (
        <footer>
            <div className="footer-left">
                <p>
                    E-CRAFT © {year} <br />
                    Wykonane przez Nomand <br />
                    Wszelkie Prawa Zastrzeżone 
                </p>
            </div>

            <div className="footer-right">
                <p>
                    <a target="_blank" rel="noreferrer" href={discord}> <FAI icon={faDiscord} /> </a>
                    <a target="_blank" rel="noreferrer" href={youtube}> <FAI icon={faYoutube} /> </a>
                    <a target="_blank" rel="noreferrer" href={teamspeak}> <FAI icon={faTeamspeak} /> </a>
                </p>
            </div>
        </footer>
	);
}

export default Footer;
