import '../../styles/rules.css';

import { NavLink } from 'react-router-dom';

function ShopRules() {
	return (
		<div className="rules">

                  <NavLink to="/regulamin"> <p className="rules-link"> ZOBACZ REGULAMIN SERWERA </p> </NavLink>

                  <p className="rules-title"> Regulamin Sklepu </p>

                  <p className="rules-header"> 1. Definicje </p>

                  <p className="rules-text">
                  a) Użytkownik - Osoba korzystająca z https://e-craft.pl <br />
                  b) Kupujący - Użytkownik który zapłacił za usługę na tej stronie. <br />
                  c) Nick – Nazwa służąca do identyfikacji Kupującego w grze. <br />
                  d) Właściciel/Administrator - Rafał Aniszewski wykonujący działalność gospodarczą pod firmą: <br />
                  <br />
                  E-CRAFT RAFAŁ ANISZEWSKI, <br />
                  NIP: 8341891323, <br />
                  REGON: 385437490 <br />
                  E-MAIL: xN0MANDx@wp.pl <br />
                  Placencja nr 49, 99-400 Łowicz <br />
                  </p>

                  <p className="rules-header"> 2. Postanowienia Ogólne </p>

                  <p className="rules-text">
                  a) Serwer E-CRAFT.PL w żaden sposób nie jest powiązany z firmą Mojang AB. <br />
                  b) Regulamin w każdej chwili może ulec zmianom bez potrzeby informowania Użytkowników. <br />
                  c) Usługi oferowane w sklepie, są przedmiotami wirtualnymi dostępnymi jedynie w formie elektronicznej na E-CRAFT.PL. <br />
                  </p>

                  <p className="rules-header"> 3. Płatności </p>

                  <p className="rules-text">
                  a) Dostępne formy płatności to: PAYSAFECARD, PRZELEWY (PRZELEW BANKOWY, PAYPAL, BLIK). <br />
                  b) Płatności PRZELEWEM są obsługiwane przez firmę HotPay. <br />
                  c) Płatności PAYSAFECARD są obsługiwane przez firmę Paybylink. <br />
                  d) Korzystanie z płatności PAYSAFECARD jest jednoznacze z akceptacją <a className="rules-reference" target="_blank" rel="noreferrer" href="https://paybylink.pl/partner/dokumenty/"> regulaminów </a> Paybylink. <br />
                  e) Wszelkie usługi, są własnością E-CRAFT.PL, a Użytkownik dostaje do nich jedynie dostęp. <br />
                  f) Nie ponosimy odpowiedzialności za błędnie wprowadzone dane przez Użytkownika. <br />
                  g) Po zakupie wybranej usługi jest ona automatycznie nadawana Kupującemu. <br />
                  </p>

                  <p className="rules-header"> 4. Reklamacje </p>

                  <p className="rules-text">
                  a) Po prawidłowym dostarczeniu usługi kupującemu przez nasz sklep nie przyjmujemy zwrotów - odstąpienie nie obowiązuje. <br />
                  b) W sytuacji prawidłowo wykonanej transakcji i nie otrzymania usługi należy zgłosić to Administratorowi podając dokładną datę, czas oraz nick. <br />
                  c) Rozpatrzenie reklamacji odbywa sie do maksymalnie 14 dni. <br />
                  d) Wszystkie reklamacje należy zgłosić pod adres e-mail: xn0mandx@wp.pl
                  </p>

                  <p className="rules-header"> 5. Przetwarzanie Danych Osobowych / Polityka Prywatności </p>

                  <p className="rules-text">
                  a) Dane Użytkownika są zbierane w momencie rejestracji na serwerze i kupowania usług w sklepie. <br />
                  b) Dane są przetwarzane w celu zapewnienia niezbędnego działania usług. <br />
                  c) Zebrane dane nie są w żaden sposób rozpowszechniane. <br />
                  d) Administratorem danych osobowych jest Właściciel tego serwisu. <br />
                  e) Istnieje możliwość dostępu i usunięcia swoich danych osobowych po przez kontakt z Administratorem. <br />
                  f) Zbierane dane przez Administratora to adres e-mail oraz adres ip. <br />
                  </p>

                  <p className="rules-header"> 6. Postanowienia Końcowe </p>

                  <p className="rules-text">
                  a) Właściciel zastrzega sobie prawo do wszelkich zmian w regulaminie. <br />
                  b) Użytkownik kupujący usługę w sklepie akceptuje przedstawiony tutaj regulamin. <br />
                  c) Nieznajomość regulaminu nie zwalnia z odpowiedzialności. <br />
                  </p>
            </div>
	);
}

export default ShopRules;
