import { useState, useEffect } from 'react';
import axios from 'axios';

import PanesContainer from './PanesContainer';
import render from '../assets/images/render.png';
import '../styles/components/server.css';

function Server() {

    // const [serverOnline, setServerOnline] = useState();
    const [playerCount, setPlayerCount] = useState('?');
    const [usersAmount, setUsersAmount] = useState('?');
    const [guildsAmount, setGuildsAmount] = useState('?');

    useEffect(() => {
		axios({
			method: "get",
			url: `https://mcapi.us/server/status?ip=e-craft.pl&port=25565`
		}).then((result) => {
            // setServerOnline(result.data.online);
			setPlayerCount(result.data.players.now);
		})

        axios({
			method: "get",
			url: `https://api.e-craft.pl/usersAmount`
		}).then((result) => {
            setUsersAmount(result.data.amount);
		})

        axios({
			method: "get",
			url: `https://api.e-craft.pl/guildsAmount`
		}).then((result) => {
            setGuildsAmount(result.data.amount);
		})
    }, [])

	return (
        <div>

            <div className="server">

                <div className="server-description"> 

                    <p className="server-description-header"> O SERWERZE </p>

                    E-CRAFT to serwer RPG w minecraft, który swój początek miał w 2015 roku! <br />
                    W 2024 postanowiliśmy wydać nową bardzo rozbudowaną edycje na podstawie całego zdobytego przez nas doświadczenia.
                    Rozpocznij swoją przygodę na naszym serwerze, przemierzaj przeróżne krainy, badaj niebezpieczne lochy, rozwiązuj tajemnice, wykonuj zadania,
                    zdobądź sojuszników, gildie i stańcie się najlepszymi z najlepszych!
                    Czekają na Ciebie setki, jeżeli nie tysiące godzin fascynującej rozrywki - na co jeszcze czekasz ? Przywdziej swoją zbroję i ruszaj w poszukiwaniu przygód!

                    <div className="server-description-btns">
                        <a target="_blank" rel="noreferrer" href="https://discord.com/invite/753jtHG">
                            <p className="server-discord-btn">
                                DOŁĄCZ DO DISCORDA
                            </p>
                        </a>
                    </div>
                </div>

                <div className="server-image">
                    <img src={render} alt="Obrazek" />
                </div>

            </div>

            {/* Panele Informacyjne */}

            <PanesContainer
                playerCount={playerCount}
                usersAmount={usersAmount}
                guildsAmount={guildsAmount}
            />

        </div>
    )
}

export default Server;
