import { useState, useEffect } from 'react';
import axios from 'axios';

import '../styles/ranking.css';

import { SpinnerCircular } from 'spinners-react';

function Ranking() {

    const [data, setData] = useState();

    useEffect(() => {

		axios({
			method: "get",
			url: `https://api.e-craft.pl/ranking`
		}).then((result) => {

            const empty = 15 - result.data.length;

            for(var i=0; i<empty; i++) {
                result.data.push({
                    nick: "-",
                    guild: "-",
                    level: 0
                })
            }
            
            setData(result.data);
		})

    }, []);

	return (
		<div className="ranking">

            {data ? (
                <>
                    <p> Ranking </p>

                    <table>
                        <tbody>

                            <tr>
                                <th># </th>
                                <th>Avatar</th>
                                <th>Nick</th>
                                <th>Poziom</th>
                                <th>Gildia</th>
                            </tr>

                            {data.map((user, index) => {
                                const head = `https://minotar.net/avatar/${user.nick}/36`;

                                return (
                                    <tr key={index}>
                                        <td>{index+1}.</td>
                                        <td> <img src={head} alt=" " /> </td>
                                        <td>{user.nick}</td>
                                        <td>{user.level}</td>
                                        <td style={{ color: user.guild.length === 0 ? 'gray' : 'white' }}>{user.guild || "BRAK"}</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </>
                )
            :
                <SpinnerCircular size="10%" color="#d2003b" speed="150" />
            }
		</div>
	);
}

export default Ranking;
